.load-more {
  background: var(--white);
  padding: 24px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content-wrapper {
  width: 20vw;
}
